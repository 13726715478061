#mlb2-5256086.ml-form-embedContainer {
    box-sizing: border-box;
    display: table;
    margin: 0 auto;
    position: static;
    width: 100% !important;
}
#mlb2-5256086.ml-form-embedContainer button,
#mlb2-5256086.ml-form-embedContainer h4,
#mlb2-5256086.ml-form-embedContainer p,
#mlb2-5256086.ml-form-embedContainer span {
    text-transform: none !important;
    letter-spacing: normal !important;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper {
    background-color: #fff;
    border-width: 1px;
    border-color: #e6e6e6;
    border-radius: 3px;
    border-style: solid;
    box-sizing: border-box;
    display: inline-block !important;
    margin: 0;
    padding: 0;
    position: relative;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
    max-width: 400px;
    width: 100%;
    transform: rotate(-1deg);
    margin-bottom: 15px;
    box-shadow: 5px 5px 7px rgba(266, 221, 215, 0.5);
}
#mlb2-5256086.ml-form-embedContainer .ml-form-align-center {
    text-align: center;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
    padding: 20px 20px 0 20px;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody.ml-form-embedBodyHorizontal {
    padding-bottom: 0;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent {
    text-align: center;
    margin: 0 0 20px 0;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent h4,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent h4 {
    color: #000;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8;
    margin: 0 0 10px 0;
    text-align: center;
    word-break: break-word;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent p,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p {
    color: #000;
    font-size: 1.6rem;
    line-height: 1.8;
    font-weight: 400;
    margin: 0 0 10px 0;
    text-align: center;
}
.rss-block {
    color: #000;
    font-size: 1.6rem;
    line-height: 1.8;
    font-weight: 400;  
    padding-bottom: 20px;
}
.rss-block a {
    color: #000 !important;
    font-weight: bold;
    text-decoration: underline;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-block-form .ml-field-group {
    text-align: left !important;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent p:last-child,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p:last-child {
    margin: 0;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody form {
    margin: 0;
    width: 100%;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent {
    margin: 0 0 20px 0;
    width: 100%;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent.horozintalForm {
    margin: 0;
    padding: 0 0 15px 0;
    width: 100%;
    height: auto;
    float: left;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input::-webkit-input-placeholder,
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input::-webkit-input-placeholder {
    color: #333;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow {
    height: auto;
    width: 100%;
    float: left;
}
.ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal {
    width: 70%;
    float: left;
}
.ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-button-horizontal {
    width: 30%;
    float: left;
}
.ml-form-formContent.horozintalForm .ml-form-horizontalRow .horizontal-fields {
    box-sizing: border-box;
    float: left;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input {
    background-color: #fff;
    color: #333;
    border-color: #ccc;
    border-radius: 1.3em 0 0 1.3em;
    border-style: solid;
    border-width: 1px;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: initial;
}
#mlb2-5256086.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow button {
    background-color: #000 !important;
    border-color: #000;
    border-style: solid;
    border-width: 1px;
    border-radius: 0 1.3em 1.3em 0;
    box-shadow: none;
    color: #fff !important;
    cursor: pointer;
    font-size: 1.22rem !important;
    line-height: 1.32;
    font-weight: 700;
    margin: 0 !important;
    padding: 10px !important;
    width: 100%;
    height: auto;
}
.ml-mobileButton-horizontal {
    display: none;
}
.mc-status, .error-message {
    color: black;
}