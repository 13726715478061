:root {
  --dark: #343a40;
  --grayer: #495057;
  --gray: #6c757d;
  --gray-light: #ced4da;
  --light-gray: #adb5bd;
  --light: #dee2e6;
  --lighter: #e9ecef;
  --lightest: #faf9fa;
  --white: #fff;
  --blue: #007bff;
  --red: #dc3545;
  --bg-color: var(--white);
  --content-width: 750px;
}