.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255,255,255);
  background-color: rgba(255, 255, 255, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
  display: none;
}

.overlay-opener {
  display: none;
  text-align: center;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  list-style: none;
}

.overlay li {
  text-decoration: none;
  display: block;
  transition: 0.3s;
  list-style-type: none;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
}

.overlay .active > a {
  color: #e74c3c;
}

.overlay a {
  text-decoration: none;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  cursor: pointer;
}

@media screen and (max-width: 512px) {
  .overlay {
    display: block !important;
  }
  .overlay-opener {
    display: block !important;
  }
}

@media screen and (max-height: 450px) {
  .overlay {
    overflow-y: auto;
    display: block !important;
  }
  .overlay li {
    font-size: 20px
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}