@import url('https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica|IM+Fell+English+SC');

body{
  height: 100%;
  text-decoration-skip: ink;
  color: #2f2f2f;
  padding-top: 10px;
  font-family: 'IM Fell DW Pica', serif;
  background-color: #f8f8f8;
}

body::selection{
  background: #fff2a8;
}

p::selection{
  background: lightgrey;
}

.image {
    margin: 0px;
}
.image img {
    width: 100%;
}
.image figcaption {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-bottom: 2.0rem;
    color: #555;
}
.image figcaption a {
    text-decoration: underline;
    color: #555;
}

.book-in-homepage {
  text-decoration: none;
}

@media only screen and (min-device-width : 768px)  {
  .book-cover {
    float: left;
    margin-right: 20px;
  }
}

@media only screen and (min-device-width : 768px)  {
  .book-cover-right {
    float: right;
    margin-left: 20px;
  }
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgba(47, 47, 47, 0.6)
}

a:hover {
  color: #e74c3c;
}

.bookmenu-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.bookmenu {
  flex: none;
}

.navlist {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.navlist a, .bookmenu {
  text-decoration: none;
}

.navlist a:hover, .bookmenu:hover {
  color: #e74c3c;
}

.navlist > .active > a {
  color: #e74c3c;
}

.navlist li, .bookmenu {
  list-style-type: none;
  padding-right: 20px;
  text-transform: uppercase;
  font-size: 1.8rem;
  white-space: nowrap;
  text-align: center;
}

@media only screen and (max-width : 728px)  {
  .navlist li, .bookmenu {
    font-size: 1.4rem !important;
  }
}

@media only screen and (max-width : 400px)  {
  .bookmenu {
    font-size: 1.2rem !important;
    padding-right: 8px !important;
  }
}

@media only screen and (max-width : 512px)  {
  .navlist-container {
    display: none !important;
  }
}

.author{
  font-size: 1.3em;
  padding-top: 20px;
}

.tagline{
  padding-top: 20px;
}

.brand{
  font-family: 'IM Fell English SC', serif;
}

.line{
  max-width: 100%;
  display: block;
  margin: 0 auto;
  -webkit-filter: opacity(.8);
  filter: opacity(.8);
}

.space{
  letter-spacing: 10px;
}

.flip{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.tag{
  width: 50%;
  margin-right:0;
}

.center{
  text-align: center;
}

.right{
  text-align: right;
}

.justify {
  text-align: justify;
}

hr.double {
	border-top: 4px double #2f2f2f;
}

hr.simple {
	border-top: 2px solid #2f2f2f;
}

.tag-hr{
  width: 45%;
  margin-right:0;
}

a:hover {
  color: #e74c3c;
}
.link{
  text-decoration: none;
}
a.link {
  color: #2f2f2f;
}
a.link:hover {
  color: #2f2f2f;
}
a.link:hover > h3 {
  color: #e74c3c;
}
a.link:hover > .mini_image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.italics{
  font-style: italic;
}

.threecol::first-letter {
  font-size: 2.5em;
}

.twocol::first-letter{
  font-size: 2.5em;
}

.threecol, .twocol{
  -ms-word-break: keep-all;
  word-break: keep-all;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
}

.articles {
  line-height: 1.6;
}
.four.columns .justify {
  margin-bottom: 4rem;
}
.articles .row h3 {
  font-variant: small-caps;
  font-size: 2.5rem;
  line-height: 2.6rem;
}

.mini_image {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

h2, h3 {
  font-variant: small-caps;
}

.infinite-spinner {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #333;
  margin: 60px auto;
  
  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@media only screen
and (min-device-width : 768px)  {
  .threecol {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
    width: 100%;
  }
  .twocol {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    width: 100%;
  }
  .tag{
    width: 23%;
    margin-right:0;
  }
}

/* Progress bars */
.pb-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 140px;
  clear: both;
}
.pb-container-breaker {
  clear: both;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #222;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 27px;
  border: 2px solid #222;
  display: block;
  text-align: center;
  margin: 0 auto 10px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #f8f8f8;
}
.progressbar li:before {
  content: '';
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #222;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.pb-active {
  color: black;
}
.progressbar li.pb-active:before {
  border-color: black;
}
.progressbar li.pb-active+li:after {
  background-color: black;
}
.progressbar li.pb-in-progress:before {
  content: "..."}
.progressbar li.pb-in-progress+li:after {
  background-color: #222;
}
.progressbar li.pb-complete:before {
  content: "\2713";
  background-color: black;
  color: #f8f8f8;
}

@media (max-width:479px) {
  .progressbar {
    padding-left: 10px;
  }
}

/* book page */
.book-title {
  font-style: italic;
}
.chapter-illustration-span {
  width: 100%;
}
.chapter-illustration {
  width: 400px;
  margin:0 auto;
}