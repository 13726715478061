/*
    Right/Left buttons to change page
*/
.navigation {
  position: fixed;
  top: 50px;
  bottom: 0;
  margin: 0;
  max-width: 150px;
  min-width: 90px;
  @include flex();
  @include flex-column();
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 40px;
  color: var(--gray-light);
  text-align: center;
  @include transition(all 0.35s ease);
}
.navigation:hover {
  text-decoration: none;
  color: var(--gray);
}
.navigation svg {
  color: var(--gray-light);
}
.navigation svg:hover {
  text-decoration: none;
  color: var(--gray);
}
.navigation.navigation-next {
  right: 20px;
}
.navigation.navigation-prev {
  left: 0;
}
@media (max-width: 1640px) {
  .navi-title {
    display: none !important;
  }
}
@media (max-width: 1023px) {
  .navigation {
    display: none !important;
  }
}
.navigation:hover .navi-title {
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}
.navigation.navigation-prev .navi-title {
  left: 100px;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}
.navigation.navigation-next .navi-title {
  right: 100px;
  text-align: right;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.navi-title {
  font-size: 14px;
  opacity: 0;
  position: absolute;
  text-align: left;
  transition: opacity .35s,-webkit-transform .35s;
  transition: transform .35s,opacity .35s;
  transition: transform .35s,opacity .35s,-webkit-transform .35s;
  width: 15vw;
}
.navi-title-elem {
  position: relative;
}
.navi-title-elem a {
  text-decoration: inherit;
  font-size: inherit;
  color: var(--light-gray);
}
.navi-elem-selected a {
  text-decoration: inherit;
  font-size: inherit;
  color: var(--dark);
}
.navi-title-elem a:visited {
  color: var(--light-gray);
}
.navi-elem-selected a:visited {
  color: var(--dark);
}
.navi-title-elem a:hover {
  color: var(--gray);
}
.navigation-links-bottom-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.navigation-links-bottom-page a {
  flex: 1 1 auto;
}
.navigation-links-bottom-page .prev-chapter {
  text-align: left;
}
.navigation-links-bottom-page .next-chapter {
  text-align: right;
}