@mixin flex() {
  display: flex!important;
  display: -webkit-box;
  display: -ms-flexbox;
}

@mixin flex-column() {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin transition($val) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
}