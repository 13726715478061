.content-type-page .social-footer, .content-type-post .social-footer {
    display: none;
}
.social-footer .social-icon-footer-link {
    width: 2.5vw;
    height: 2.5vw;
    display: inline-block;
    vertical-align: middle;
    background-color: #000;
    color: white;
    border-radius: 0.6vw;
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 0.1vw;
    padding-top: 0.4vw;
    text-align: center;
}
.social-footer .social-facebook:hover {
    background-color: #3b5998;
    color: white;
}
.social-footer .social-twitter:hover {
    background-color: #00b6f1;
    color: white;
}
.social-footer .social-pinterest:hover {
    background-color: #c91517;
    color: white;
}
.social-footer .social-wordpress:hover {
    background-color: #00749C;
    color: white;
}
.social-footer .social-instagram:hover {
    background-color: #2c6a93;
    color: white;
}
.social-footer .social-linkedin:hover {
    background-color: #0072b1;
    color: white;
}

.social-footer .social-github:hover {
    background-color: #6f42c1;
    color: white;
}

.social-footer .social-medium:hover {
    background-color: #28a745;
    color: white;
}
#footer {
    font-family: 'Futura';
    font-weight: lighter;
    font-size: 1.3rem;
    line-height: 1.5;
    background-color: black;
    color: white;
    padding-top: 0px;
    padding-bottom: 20px;
    clear: both;
    padding: 20px 0 1.75em;
    word-wrap: break-word;
    display: block;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    max-width: 100%;
    width: 100%;
}

.footer-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 20px auto 0;
    max-width: 1380px;
    box-sizing: border-box;
    padding: 0 1.75em;
}

.footer-content-area {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
}

.footer-content-area a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}

.footer-wrapper a:visited {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}

.footer-wrapper a:hover {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}
@media screen and (min-width: 1921px) {
    .social-footer .social-icon-footer-link {
        width: calc(1900px * 0.01 * 2.5);
        height: calc(1900px * 0.01 * 2.5);
        border-radius: calc(1900px * 0.01 * 0.6);
        padding-bottom: calc(1900px * 0.01 * 0.1);
        padding-top: calc(1900px * 0.01 * 0.4);
    }
    .social-icon {
        line-height: calc(1900px * 0.01 * 1.7);
        font-size: calc(1900px * 0.01 * 1.2);
    }
}
@media screen and (max-width: 1112px) {
    .social-footer .social-icon-footer-link {
        width: 3.5vw;
        height: 3.5vw;
    }
    .social-icon {
        line-height: 3vw;
        font-size: 2.2vw;
    }
}
@media screen and (max-width: 768px) {
    .social-footer .social-icon-footer-link {
        width: 6.5vw;
        height: 6.5vw;
    }
    .social-icon {
        line-height: 5.9vw;
        font-size: 4.2vw;
    }
}
@media screen and (max-width: 640px) {
    .social-footer .social-icon-footer-link {
        width: 6.5vw;
        height: 6.5vw;
    }
    .social-icon {
        line-height: 5.9vw;
        font-size: 4.2vw;
    }
}

.feed-subscribe .svg-icon {
  padding: 5px 5px 2px 0
}

.contact-list,
.social-media-list,
.policy-list {
  list-style: none;
  margin-left: 0;
}

.policy-list {
  text-align: right;
  line-height: 30px;
}